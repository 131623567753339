import { Theme, makeStyles } from "@material-ui/core";

export const signInStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
    width: theme.spacing(52),
    margin: "auto"
  },
  card: {
    minWidth: theme.spacing(51.25), // 410px
    padding: theme.spacing(6.25, 3.75)
  },
  image: { height: "auto", width: "220px", marginBottom: theme.spacing(2.5) }
}));
